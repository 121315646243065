var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Card',{attrs:{"title":_vm.title}},[_c('div',{staticClass:"form"},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 16 },"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"任务名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'name',
          {
            rules: [{ required: true, message: '请输入' }],
          },
        ]),expression:"[\n          'name',\n          {\n            rules: [{ required: true, message: '请输入' }],\n          },\n        ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"任务日期"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'time',
          {
            rules: [{ required: true, message: '请选择' }],
          },
        ]),expression:"[\n          'time',\n          {\n            rules: [{ required: true, message: '请选择' }],\n          },\n        ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":""}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"文件","label-col":{ span: 3 },"wrapper-col":{ span: 20 }}},[_c('Upload',{attrs:{"list":_vm.fileList},on:{"change":(list) => (_vm.fileList = list)}})],1)],1)],1),_c('div',{staticClass:"center",staticStyle:{"margin-top":"24px"}},[_c('a-space',[_c('a-button',{on:{"click":_vm.cancel}},[_vm._v("关闭")]),_c('a-button',{attrs:{"html-type":"submit","type":"primary","loading":_vm.loading}},[_vm._v("保存")])],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }