<template>

    <Card :title="title">
    
        <div class="form">
            <a-form :form="form" @submit="handleSubmit" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" :colon="false">
              <a-row>
                <a-col :lg="12" :md="12" :sm="24">
                  <a-form-item label="任务名称">
                    <a-input v-decorator="[
              'name',
              {
                rules: [{ required: true, message: '请输入' }],
              },
            ]" />
                  </a-form-item>
                </a-col>
      
                <a-col :lg="12" :md="12" :sm="24">
                  <a-form-item label="任务日期">
                    <a-date-picker style="width: 100%" placeholder v-decorator="[
              'time',
              {
                rules: [{ required: true, message: '请选择' }],
              },
            ]" />
                  </a-form-item>
                </a-col>
      
                <a-col :lg="24" :md="24" :sm="24">
                  <a-form-item label="文件" :label-col="{ span: 3 }" :wrapper-col="{ span: 20 }">
                    <Upload :list="fileList" @change="(list) => (fileList = list)" />
                  </a-form-item>
                </a-col>
              </a-row>
      
              <div class="center" style="margin-top: 24px;">
                <a-space>
                  <a-button @click="cancel">关闭</a-button>
                  <a-button html-type="submit" type="primary" :loading="loading">保存</a-button>
                </a-space>
              </div>
            </a-form>
          </div>
    </Card>
    
      </template>
      
      <script>
      import { add, edit, fetchDetail } from "../api";
      import moment from 'moment'
      export default {
    
        data() {
          return {
            id: '',
            title: '新建管道检测任务',
            form: this.$form.createForm(this),
            loading: false,
    
            fileList: [],
          };
        },
    
        mounted() {
          const { id } = this.$route.query;
          if (id) {
            this.id = id;
            this.title = '编辑管道检测任务'
    
            fetchDetail({id}).then(res => {
              if (!res) return;
    
              this.form.setFieldsValue({
                name: res.name,
                time: res.time ? moment(res.time) : undefined,
            })
    
            this.fileList = Array.isArray( res.fileVos)? res.fileVos            : [];
    
          });
    
          }
        },
      
      
        methods: {
    
          handleSubmit(e) {
            e.preventDefault();
            this.form.validateFields((err, values) => {
              if (!err) {
                const params = {
                  ...values,
                  time: values.time.format("YYYY-MM-DD"),
                  files: this.fileList.map(item => item.id).join(',')
                };
      
                console.log(params);
    
                this.loading = true;
                
                let task = add;
                if (this.id) {
                  task = edit
                }
                task({
                  id: this.id ? this.id : undefined,
                  ...params,
                }).then(() => {
                  this.cancel();
                }).finally(() => {
                    this.loading = false;
                })
              }
            });
          },
      
          cancel() {
            this.$router.go(-1);
          },
        },
      };
      </script>
      
      <style lang="less" scoped>
    
      </style>
      